<template>
	<v-container fluid>
		<base-material-card
			icon="fa fa-truck"
			title="Reporte de Devoluciones"
			class="px-5 py-3"
		>
			<v-row>
				<v-col cols="6">
					<custom-date label="Fecha Desde" :value="form.fecha_desde"
					@change="(value)=>{form.fecha_desde = value;reset()}"
					>
					</custom-date>
				</v-col>
				<v-col cols="6">
					<custom-date label="Fecha Hasta" :value="form.fecha_hasta"
					@change="(value)=>{form.fecha_hasta = value;reset()}"
					>
						</custom-date>
				</v-col>
				<v-col cols="6">
					<custom-select
						url="/vendedores/all"
						text="razon_social"
						label="Vendedor"
						@change="
							(value) => {
								form.vendedor_id = value;
								reset();
							}
						"
						:valor="form.vendedor_id"
						:first-select="true"
						first-text="Todos"
					></custom-select>
				</v-col>
				<v-col cols="6">
					<custom-select
						url="/clientes/all"
						text="razon_social"
						label="Clientes"
						@change="
							(value) => {
								form.cliente_id = value;
								reset();
							}
						"
						:valor="form.cliente_id"
						:first-select="true"
						first-text="Todos"
					></custom-select>
				</v-col>
				<v-col cols="4" v-if="false">
					<custom-select
						url="/productos"
						:paginate="true"
						label="Producto"
						placeholder="Seleccione un producto"
						:valor="form.producto_id"
						@change="(value) => (form.producto_id = value)"
					></custom-select>
				</v-col>
        <v-col cols="4">
          <custom-select
            url="/rutas"
            text="nombre"
            label="Ruta"
            @change="(value) => {form.ruta_id = value; load()}"
            :paginate="false"
            :valor="form.ruta_id"
            :first-select="true"
            first-text="Todos"
          ></custom-select>
        </v-col>
				<v-col cols="12">
					<v-btn color="primary" @click="downloadPdf">Descargar PDF</v-btn>
					<v-btn color="green" @click="downloadExcel">Descargar Excel</v-btn>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-data-table
						:headers="headers"
						:items="facturas"
						:loading="loading"
						hide-default-footer
						disable-pagination
					>
            <template v-slot:item.ruta="{ item }">
              {{ item.ruta ? item.ruta.nombre : 'NA' }}
            </template>
						<template v-slot:item.subtotal="{ item }">
							{{ formatoMoneda(calcularSubtotal(item)) }}
						</template>
						<template v-slot:item.impuestos="{ item }">
							{{ formatoMoneda(calcularImpuestos(item)) }}
						</template>
						<template v-slot:item.total="{ item }">
							{{
								formatoMoneda(calcularSubtotal(item) + calcularImpuestos(item))
							}}
						</template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn fab icon x-small v-on="on" @click="showDetalles(item)">
                    <v-icon>far fa-file-alt</v-icon>
                  </v-btn>
                </template>
                <span>Movimientos</span>
              </v-tooltip>
            </template>
						<template v-slot:body.append>
							<tr>
								<td colspan="2">No. Piezas {{ totales.no_piezas }}</td>
								<td colspan="5"></td>
								<td class="font-weight-bold">Subtotal</td>
								<td class="font-weight-bold">
									{{
										totales ? formatoMoneda(totales.subtotal) : formatoMoneda(0)
									}}
								</td>
								<!-- <td class="font-weight-bold"></td> -->
							</tr>
							<tr v-for="(impuesto, key) in totales.impuestos" :key="impuesto">
								<td colspan="7"></td>
								<td class="font-weight-bold">{{ key }}</td>
								<td class="font-weight-bold">
									{{ formatoMoneda(impuesto) }}
								</td>
								<!-- <td class="font-weight-bold"></td> -->
							</tr>
							<tr>
								<td colspan="7"></td>
								<td class="font-weight-bold">Total</td>
								<td class="font-weight-bold">
									{{
										totales ? formatoMoneda(totales.total) : formatoMoneda(0)
									}}
								</td>
								<!-- <td class="font-weight-bold"></td> -->
							</tr>
						</template>
					</v-data-table>
				</v-col>
				<v-col col="12">
					<v-pagination
						v-model="form.page"
						class="my-4"
						:length="form.last_page"
						@input="load"
						:total-visible="10"
						color="primary"
					></v-pagination>
				</v-col>
			</v-row>
		</base-material-card>
    <v-dialog v-model="dialog">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Detalles de devoluciones</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-simple-table v-if="devolucion">
                <thead>
                <tr>
                  <th>Cantidad</th>
                  <th>Producto</th>
                  <th>Precio</th>
                  <th>Subtotal</th>
                  <th>Impuestos</th>
                  <th>Total</th>
                </tr>
                </thead>
                <tr v-for="descripcion in devolucion.detalles">
                  <td>{{descripcion.cantidad}}</td>
                  <td>{{descripcion.producto.nombre}}</td>
                  <td>{{formatoMoneda(descripcion.precio)}}</td>
                  <td>{{formatoMoneda(descripcion.importe)}}</td>
                  <td>{{formatoMoneda(calcularImpuestosDetalle(descripcion))}}</td>
                  <td>{{formatoMoneda(calcularTotalDetalle(descripcion))}}</td>
                </tr>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
	</v-container>
</template>
<script>
	import CustomSelect from "@views/dashboard/component/CustomSelect.vue";
	import CustomDate from "@views/dashboard/component/CustomDate.vue";

	export default {
		components: {
			CustomSelect,
			CustomDate,
		},
		data: () => ({
			errors: {},
      dialog:false,
      devolucion:null,
			headers: [
				{ text: "#", value: "id", sortable: false },
				{ text: "Fecha", value: "fecha", sortable: false },
				{ text: "Vendedor", value: "vendedor.razon_social", sortable: false },
				{
					text: "Cliente",
					value: "sucursal.cliente.razon_social",
					sortable: false,
				},
				{ text: "Sucursal", value: "sucursal.nombre", sortable: false },
				{ text: "Ruta", value: "ruta", sortable: false },
				{ text: "Subtotal", value: "subtotal", sortable: false },
				{ text: "Impuestos", value: "impuestos", sortable: false },
				{ text: "Total", value: "total", sortable: false },
				{ text: "Acciones", value: "actions", sortable: false },
			],
			form: {
				fecha_desde: moment().startOf('day').format('YYYY-MM-DD'),
				fecha_hasta: moment().endOf('day').format('YYYY-MM-DD'),
				vendedor_id: "",
				cliente_id: "",
				producto_id: "",
				ruta_id: "",
				last_page: 1,
				page: 1,
			},
			loading: false,
			facturas: [],
			total: 0,
			totales: {
				impuestos: [],
			},
		}),
		mounted() {
			this.load();
		},
		methods: {
      calcularImpuestosDetalle(detalle){
        return detalle.impuestos.reduce((previousValue, currentValue) => previousValue + parseFloat(currentValue.pivot.importe),
          0);
      },
      calcularTotalDetalle(detalle){
        return detalle.importe + this.calcularImpuestosDetalle(detalle);
      },
      showDetalles(item){
        this.devolucion = item;
        this.dialog = true;
        console.log('showDetalles', item);
      },
			calcularSubtotal(item) {
				return item.detalles.reduce(
					(total, product) => total + product.importe,
					0
				);
			},
			calcularImpuestos(item) {
				return item.detalles.reduce(
					(total, producto) => total + this.calcularImpuesto(producto),
					0
				);
			},
			calcularImpuesto(producto) {
				if (producto && producto.impuestos) {
					return producto.impuestos.reduce(
						(total, impuesto) => total + parseFloat(impuesto.pivot.importe),
						0
					);
				}
				return 0;
			},
			reset() {
				this.form.page = 1;
				this.load();
			},
			load() {
				this.loading = true;
				this.facturas = [];
				this.$http
					.get("/almacenes/devoluciones", {
						params: this.form,
					})
					.then((response) => {
						this.facturas = response.data.data;
						this.total = response.data.cantidad_sum;
						this.totales = response.data.totales;
						this.form.last_page = response.data.last_page;
						this.form.page = response.data.current_page;
					})
					.finally(() => {
						this.loading = false;
					});
			},
			downloadPdf() {
				this.$http({
					url: `/almacenes/devoluciones/pdf`,
					method: "GET",
					responseType: "blob", // important
					params: this.form,
				}).then((response) => {
					const downloadUrl = window.URL.createObjectURL(
						new Blob([response.data])
					);
					const link = document.createElement("a");
					link.href = downloadUrl;
					link.setAttribute("download", `ReporteDevoluciones.pdf`); //any other extension
					document.body.appendChild(link);
					link.click();
					link.remove();
				});
			},
			downloadExcel(item) {
				this.$http({
					url: `/almacenes/devoluciones/excel`,
					method: "GET",
					responseType: "blob", // important
					params: this.form,
				}).then((response) => {
					const downloadUrl = window.URL.createObjectURL(
						new Blob([response.data])
					);
					const link = document.createElement("a");
					link.href = downloadUrl;
					link.setAttribute("download", `ReporteDevoluciones.xlsx`); //any other extension
					document.body.appendChild(link);
					link.click();
					link.remove();
				});
			},
		},
	};
</script>
