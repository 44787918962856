<template>
    <v-menu v-model="menu" :close-on-content-click="false" 
    :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
        <template v-slot:activator="{ on }">
            <v-text-field :value="value"
            :label="label" readonly v-on="on" 
            :error-messages="errorMessages">
            </v-text-field>
        </template>
        <v-date-picker :value="value" 
        @change="(newValue)=>$emit('change', newValue)"
        @input="menu = false" locale="es-MX">
        </v-date-picker>
    </v-menu>
</template>
<script>

export default ({
    name:"custom-date",
    props:{
        label:{
            type: String,
            required: false
        },
        errorMessages:{
            type: [String, Array],
            required: false
        },
        value:{
            type: String,
            required: true
        },
    },
    data(){
        return{
            menu:false,
        }
    }
})
</script>
